import format from "../../../../../utils/format";
import calculateFees from "./calculateFees";

interface IInstallment {
    label: string;
    value: string;
}

export default function generateInstallments(totalPrice, anyPrice = 'no') {

    const installment: Array<IInstallment> = [];
    for (let x = 1; x <= (anyPrice === 'yes' ? 1 : 3); x += 1) {

        installment.push({
            label: `${x}X ${format(
                Math.floor(calculateFees(totalPrice, x, 0.016) / x),
            )}${x >= 2 ? ' com juros' : ''}`,
            value: x?.toString(),
        });
    }



    return installment;
}