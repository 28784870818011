import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBarcode, FaBell, FaCertificate, FaCopy, FaFile, FaIdBadge, FaImages, FaPaperPlane, FaQrcode, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser, FaUserPlus, FaWhatsapp } from 'react-icons/fa';

import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import api from '../../../../services/api';

import QRCode from 'qrcode.react';
import { Container, ContainerWhite } from './styles';
import { useModal } from '../../../../hooks/Modal';
import QRCodeTicketComponent from '../../../DashboardPages/core/components/QRCodeTicketComponent';
import { useToast } from '../../../../hooks/Toast';
import { useLoading } from '../../../../hooks/LoadingHook';

interface IProps {
  changeStatus: Function;

}

const MeusIngressos: React.FC<IProps> = ({ changeStatus }) => {

  const { user } = useAuth();
  const { addModal, removeModal } = useModal();
  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])

  const { projectHash, configuration } = useConfiguration();
  const { translate } = useLanguage();
  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    addToast({ type: 'success', 'title': translate('Copiado para o Clipboard') })

  }

  const applyCourtesy = async (email, hashValue) => {
    addLoading({ title: 'Aplicando ingresso', key: 'aplicar-cortesia' })

    try {
      const checkCourtesy = await api.post(`/v3/confirm-ticket-transfer/${hashValue}`, { email: email });

      if (checkCourtesy?.data?.confirmed === 'yes') {
        addToast({ title: 'Confirmado com sucesso!', type: 'success' });

        load();
        removeModal('selfApply');
        removeModal('confirmSelfApply');
      }
      else if (checkCourtesy?.data?.used === 'yes') {
        addToast({ type: 'error', title: 'Este ingresso já foi transferido para um usuário' });
      }
      else {
        addToast({ type: 'error', title: 'Erro ao transferir ingresso' });
      }
      removeLoading('aplicar-cortesia')

    }
    catch (err) {

      addToast({ type: 'error', title: 'Erro ao transferir ingresso' });
      removeLoading('aplicar-cortesia')
    }


  }

  const getPaymentMethod = {

    boleto: "Boleto",
    credit_card: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }


  const load = async () => {



    const response = await api.get(`/my-qrcodes/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  useEffect(() => {



    load();


  }, [])

  const showTicket = (data) => {

    addModal({
      title: '', theme: 'whiteModalMini', content: <div>

        <QRCodeTicketComponent sale={data} />
      </div>
    })

  }

  const confirmateSelfApply = (data) => {

    addModal({
      title: '', theme: 'whiteModalMedium', content: <div>

        <p>Você confirma a aplicação deste ingresso à este cadastro?</p>
        <p>Usuário: {user?.name}</p>
        <button onClick={() => { applyCourtesy(user?.email, data?.ticket_hash) }} className='greenButton2 buttonColored'>Confirmar</button>
      </div>
    })

  }

  const showTicketOptions = (data) => {

    const text = `Olá! Seu ingresso para o evento ${configuration?.current_event_id_response?.title} já está disponível! Acesse ${urlWebsite}/app/${data?.project_id}/validar-ingresso/${data?.ticket_hash} e confirme sua inscrição.`;

    addModal({
      title: '', theme: 'whiteModalMini', content: <div>
        <p style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'center' }}>Selecione a opção que deseja.</p>
        <a href={`${urlWebsite}/app/${data?.project_id}/validar-ingresso/${data?.ticket_hash}`} target="_BLANK"> <button className='greenButton2 buttonColored pad' ><FaUserPlus /> Cadastrar novo participante</button></a>
        <button className='greenButton2 buttonColored pad' onClick={() => confirmateSelfApply(data)} ><FaUser /> Aplicar ao meu cadastro</button>
        <a href={`https://api.whatsapp.com/send?text=${encodeURI(text)}`} target={`_BLANK`}><button className='greenButton2 buttonColored pad' ><FaWhatsapp /> Enviar por whatsapp</button></a>
        <button onClick={() => copyToClipboard(`${urlWebsite}/app/${data?.project_id}/validar-ingresso/${data?.ticket_hash}`)} className='greenButton2 buttonColored pad' ><FaCopy /> Copiar link</button>


      </div>
    })

  }

  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>{translate('Meus ingressos')}</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr>
                <th>{translate('Ingresso')}</th>



              </tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}>


                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                      <strong>{sale?.product_id_response?.title}</strong><br />
                      Titular: {sale?.client_id_response?.name || 'Pendente de transferência'}<br />
                      Comprador: {sale?.payer_id_response?.name}<br />
                      Status: {sale?.payment_status_id === 'paid' ? 'Ativo' : 'Inativo'}<br /><br />
                      <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        {/* sale?.payment_status_id === 'paid' ? sale?.client_id ? <>
                          <button className='greenButton2 buttonColored pad' onClick={() => showTicket(sale)}><FaQrcode /> Ingresso</button>
                          <button className='greenButton2 buttonColored pad' onClick={() => showTicketOptions(sale)}><FaPaperPlane /> Transferir ingresso</button>
                </> : <button className='greenButton2 buttonColored pad' onClick={() => showTicketOptions(sale)}><FaPaperPlane /> Transferir ingresso</button> : <><p>Ingresso indisponível </p></>} */}
                      </div>
                    </td>

                    {/*   <td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td>  */}



                  </tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>{translate('Nenhum pagamento identificado')}</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default MeusIngressos;
