import React, { useEffect, useState } from 'react';
import { FaCheck, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { useModal } from 'src/hooks/Modal';
import Input from '../components/Forms/Input';
import MaskedInput from '../components/Forms/MaskedInput';

import SelectSimple from '../components/Forms/SelectSimple';
import { useLanguage } from "../hooks/Language"
import { countries } from "../pages/Products/modules/Login/countries";
import api from '../services/api';
import states from './SignUp/FormContainer/states';

interface IProps {
    changeCountry: Function;
    countryISO: string;
    checkUserValue?: Function;
    cart?: Record<string, any>[];
    key?: string;
    keyName?: string;
}

export const checkDocumentNumber = async (document_number) => {

    const doc = document_number?.toString().replace(/[^0-9]/g, '');
    const valid = await api.get('check-user-document_number', { params: { document_number: doc } });

    return valid?.data?.valid || 'no';

}


const DefaultSignUpFormV4: React.FC<IProps> = ({ key = 'product', keyName = 'product', checkUserValue = (value) => { }, changeCountry, cart }) => {

    const [countryISO, setCountryISO] = useState('BR')
    const [checkUser, setCheckUser] = useState('')
    const [currentDocumentNumber, setCurrentDocumentNumber] = useState('');
    const { translate } = useLanguage();

    const { addModal } = useModal();

    useEffect(() => {
        setCheckUser('');
        checkUserValue('');
    }, [keyName])
    const verifyAssociate = async (doc, product) => {



        try {

            const validate = await api.get(`/verify-association-api/${product?.hash_link?.toString()}/${product?.project_id}/${doc}`);

            if (validate.status === 200) {
                return true;
            }
            else {
                return false;
            }


        }
        catch (err) {

            addModal({ theme: 'whiteModalMini', title: '', content: <> <p style={{ color: '#333', padding: '10px', fontSize: '14px', textAlign: 'center', width: '100%' }}>{err?.response?.data?.message || err?.response?.data?.error || 'Produto exclusivo para associados'} </p></> })
            return false;
        }



    }


    return <>
        <SelectSimple index={1} mandatory={true} setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />


        {countryISO === 'BR' ? <MaskedInput mandatory={true}
            mask="999.999.999-99"
            name="document_number"
            setCallback={(value) => setCurrentDocumentNumber(value)}
            placeholder={translate('CPF')}
        /> : <Input setCallback={(value) => setCurrentDocumentNumber(value)} mandatory={true} name="document_number"
            placeholder={translate('Nº do documento')} />}

        {!checkUser ? <button type='button' style={{ margin: '5px auto' }} onClick={async () => {


            const product = cart?.[0];

            if (product?.allowed_only === 'Sim') {
                const checkAssociation = await verifyAssociate(currentDocumentNumber, product);
                if (!checkAssociation) {
                    return;
                }
                else {
                    const check = await checkDocumentNumber(currentDocumentNumber);
                    setCheckUser(check);
                    checkUserValue(check);
                }
            }
            else {

                const check = await checkDocumentNumber(currentDocumentNumber);
                setCheckUser(check);
                checkUserValue(check);

            }



        }} className='greenButton2 buttonColored'>Validar</button> : <></>}
        {checkUser === 'no' ? <>
            {countryISO === 'BR' ? <SelectSimple mandatory={true} index={2} options={states} name="state" label="Estado" /> :

                <Input name="state" placeholder={'Província / Região'} />}
            <Input name="city" placeholder={'Município'} />
            <Input mandatory={true} name="name" placeholder={translate('Nome completo')} />

            {countryISO === 'BR' ? <MaskedInput
                mandatory={true}
                mask="(99) 99999.9999"
                name="phone"
                placeholder={translate('Telefone / Whatsapp')}
            /> : <Input name="phone" mandatory={true}
                placeholder={translate('Telefone')} />}







            <MaskedInput mandatory={true}
                mask="99/99/9999"
                name="extra_1"
                placeholder={translate('Data de nascimento')}
            />




            <Input mandatory={true} name="company" placeholder={translate('Instituição/Empresa')} />
            <Input mandatory={true} name="category_id" placeholder={translate('Área de atuação/Interesse')} />
            <SelectSimple mandatory={true} index={5} options={[
                { label: 'Homem Cis (sexo biológico e identidade de gênero iguais)', value: 'Homem Cis' },
                { label: 'Homem Trans', value: 'Homem Trans' },
                { label: 'Mulher Cis (sexo biológico e identidade de gênero iguais)', value: 'Mulher Cis' },
                { label: 'Mulher Trans', value: 'Mulher Trans' },
                { label: 'Não binário', value: 'Não binário' },
                { label: 'Travesti', value: 'Travesti' },
                { label: 'Outra', value: 'Outra' },
                { label: 'Prefiro não informar', value: 'Prefiro não informar' },
            ]} name="gender_id" label='Gênero' />

            <SelectSimple mandatory={true} index={10} options={[

                { label: 'Branca', value: 'Branca' },
                { label: 'Preta', value: 'Preta' },
                { label: 'Parda', value: 'Parda' },
                { label: 'Indígena', value: 'Indígena' },
                { label: 'Amarela', value: 'Amarela' },
                { label: 'Prefiro não informar', value: 'Prefiro não informar' },
            ]} name="extra_5" label='Raça' />
            <Input name="crm" placeholder={translate('CRM')} />
            <SelectSimple mandatory={true} index={15}

                options={[
                    { label: 'WhatsApp', value: 'WhatsApp' },
                    { label: 'Indicação', value: 'Indicação' },
                    { label: 'Instagram', value: 'Instagram' },
                    { label: 'Facebook', value: 'Facebook' },
                    { label: 'Linkedin', value: 'Linkedin' },
                    { label: 'E-mail marketing', value: 'E-mail marketing' },
                    { label: 'SMS', value: 'SMS' },
                    { label: 'Workplace', value: 'Workplace' },
                    { label: 'Google', value: 'Google' },
                    { label: 'Outros', value: 'Outros' },



                ]}
                name="know_about"

                label="Como soube do evento?"
            />

            <p style={{ fontSize: '14px', textAlign: 'center', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px', color: '#333' }}> {translate('Crie seu acesso')}</p>

            <Input mandatory={true} name="email" placeholder={translate('E-mail')} />


            <Input mandatory={true}
                icon={FiLock}
                name="password"
                type="password"
                placeholder={translate('Cadastre sua senha de acesso')}
            />
        </> : <></>}

        {checkUser === 'yes' ? <div className='row'><FaCheck style={{ color: 'green', fontSize: '18px' }} /> <p>Usuário identificado</p></div> : <></>}


    </>
}

export default DefaultSignUpFormV4;