export const hash = '27b2b26a-c631-4775-bd07-b902921fab56';

// export const gateKey = '';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';



//export const apiSocket = 'http://localhost:8080';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const websitePath = 'https://meetingssbggsp.com.br';
export const urlWebsite = 'https://meetingssbggsp.com.br';
export const supportNumber = `5551980332025`;
export const home = 'Home';
export const haveTranslate = false;
export const defaultProjectHash = 'sbgg';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'SBGG Meetings - São Paulo';
export const logo = '/assets/logo2.png';
export const background = '/assets/Fundo.jpg';
export const authTitle = 'SBGG Meetings - São Paulo';
export const dadosOrganizacao = '';
export const social = {
    whatsapp: '',
    instagram: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    spotify: '',
    twitter: '',
};


export const defaultTheme = {
    defaultBackground: '#fff',
    defaultColor: '#333',
    logo: {
        minWidth: '450px',
    },
    menu: {
        background: '#fff',
        backgroundSticky: '#fff',
        backgroundMobile: '#333',
        color: '#333',
        shadow: '0',
        logoColor: 'none',
    },
};

export const getToken = (): string | null => {
    const token = localStorage.getItem(`@${authTitle}:token`);
    return token;
};
